import React, { useState, useEffect } from "react";
import axios from "axios";
import tvimage from '../../../src/img/tv.png';
import { Link } from "react-router-dom";

const Serieslist = ({ match }) => {
  const [matches, setMatches] = useState([]);
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    const formattedDate = new Date(dateTimeString).toLocaleString(undefined, options);
    return formattedDate.replace(/\b(\d{1,2}:\d{2})\s+(AM)\b/i, '$1 PM');
  };


  useEffect(() => {
    const token = localStorage.getItem("token_store_cricket");
    const searchParams = new URLSearchParams(window.location.search);
    const seriesId = searchParams.get("series_id");
    const sportID = searchParams.get("sport_id");
    const eventID = searchParams.get("event_id");

    if (seriesId) {
      const axiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      axiosInstance
        .get(
          `${process.env.REACT_APP_API_URL}/series-ids?series_id=${seriesId}&sport_id=${sportID}&event_id=${eventID}`
        )
        .then((response) => {
          setMatches(response.data.data.matches[0].series);
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const onclickmatch = (id, event_id, name) => {
    const url = `/cricket?id=${id}&event_id=${event_id}&name=${name}`;
    window.location.href = url;
  };





  return (
    <div>
      <div class="card-header tab_first d-flex displaynone_mobile">
        <div>

        </div>
      </div>
      <main id="main" className="main">
        <section className="section dashboard">
          <div className="card padding_custum">
            <div
              id="cricket12"
              class="tab-pane fade show in active"
              role="tabpanel"
              aria-labelledby="tab-Cricket"
            >
              <div class="table_responsive">
                <table className="table gametable">
                  
                  <tbody>


                    {matches.map((match) => {
                      const currentDate = new Date();
                      const options = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
               
                      };
                      const formattedCurrentDate = currentDate.toLocaleString(undefined, options);
                      const matchDate = new Date(match.date_time);

                      const isToday = matchDate.toDateString() === currentDate.toDateString();
                      // alert(isToday);
                      console.log(isToday);
                      console.log(match.date_time)
                      const isLive = isToday && currentDate >= matchDate;

                      return (
                        <tr key={match.id}>
                          <td scope="row">
                            <div className="d-flex justify-content-between">
                              <div className='gamelink '>
                                <span className="date">{formatDateTime(match.date_time)}</span>
                                <Link onClick={() => onclickmatch(match.id, match.event_id, match.name)}>
                                  {match.name}
                                </Link>
                                {isLive ? (
                                  <>
                                    <div className='d-flex align-items-center'>
                                      <span className="live-text" style={{ color: '#000', paddingLeft: '10px' }}>Live</span>
                                      <span className="activegreen mx-2" />
                                    </div>
                                  </>
                                ) : (
                                  <>

                                  </>
                                )}
                              </div>
                              <div className="d-flex justify-content-end align-items-center activeanddeactive">


                                <div className="bm">
                                  <img src={tvimage} className="img-fluid" alt="tv" />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='mobileheading'>
                            <div className="d-flex justify-content-around">
                              <div className='headinggame'>1</div>
                              <div className='headinggame'>x</div>
                              <div className='headinggame'>2</div>
                            </div>
                          </td>
                          <td className='d-flex headinggame position-relative'>
                            <div className="d-flex ">
                              <div className="bg_bluecolor"><button>1.00</button></div>
                              <div className="bg-color-2"><button>1.00</button></div>
                            </div>
                            <div className="d-flex suspended">
                              <div className="bg_bluecolor"><button>1.00</button></div>
                              <div className="bg-color-2"><button>1.00</button></div>
                            </div>
                            <div className="d-flex suspended">
                              <div className="bg_bluecolor"><button>1.00</button></div>
                              <div className="bg-color-2"><button>1.00</button></div>
                            </div>
                          </td>


                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <table class="table table-striped table-bordered">
                  <tbody>
                    <tr class="matchheadingcricket">
                      <th>FIXTURES</th>
                      <th>Date and Time</th>
                      <th>Id</th>
                    </tr>
                    {matches.map((match) => (
                      <tr className="teamname_match">
                        <td className="team_name">
                          <Link onClick={() => onclickmatch(match.id, match.event_id)}>
                            {match.name}
                          </Link>
                        </td>
                        <td>
                          <p> {formatDateTime(match.date_time)}</p>
                        </td>
                        <td>{match.id}</td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Serieslist;
