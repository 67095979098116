import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FacebookShareButton, InstapaperShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
// import history from './history';
import { useNavigate } from "react-router-dom";
// import { Offcanvas,Button } from 'your-offcanvas-library';

import { Outlet, Link, Await } from "react-router-dom";
import $ from "jquery";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../../../src/App.css';
import logo from '../../../src/img/criket-logo.png';
import apk from '../../img/apkbutton.png'

import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";

const Login = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(true); 
  
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    // Event listener for beforeinstallprompt
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    // Add the event listener
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    // Cleanup: Remove the event listener
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  useEffect(() => {
    // const userid = localStorage.getItem("userid");
    // if(userid){
    //     navigate('/Home');
    // }
    // Check if the app is already installed
    const isAppInstalled = localStorage.getItem('isAppInstalled');

    if (isAppInstalled) {
      setShowInstallButton(false);
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user's choice
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // User accepted the A2HS prompt
          console.log('User accepted the A2HS prompt');

          // Mark the app as installed
          localStorage.setItem('isAppInstalled', 'true');
          setShowInstallButton(false);
        } else {
          // User dismissed the A2HS prompt
          console.log('User dismissed the A2HS prompt');
        }

        // Reset the deferredPrompt
        setDeferredPrompt(null);
      });
    }
  };
  // pwa 
    // const [deferredPrompt, setDeferredPrompt] = useState(null);

    // useEffect(() => {
   
    //   window.addEventListener('beforeinstallprompt', (event) => {
    //     event.preventDefault();
    //     setDeferredPrompt(event);
    //   });
  
      
    //   return () => {
    //     window.removeEventListener('beforeinstallprompt', (event) => {
    //       event.preventDefault();
    //       setDeferredPrompt(event);
    //     });
    //   };
    // }, []);
  
    // const handleInstallClick = () => {
    //   if (deferredPrompt) {
      
    //     deferredPrompt.prompt();
  
    
    //     deferredPrompt.userChoice.then((choiceResult) => {
    //       if (choiceResult.outcome === 'accepted') {
    //         console.log('User accepted the A2HS prompt');
    //       } else {
    //         console.log('User dismissed the A2HS prompt');
    //       }
  
      
    //       setDeferredPrompt(null);
    //     });
    //   }
    // };
    
  const [loadingsplash, setloadingsplash] = useState(true);
  useEffect(() => {
    // Simulate a delay for the splash screen (in milliseconds)
    const splashTimeout = setTimeout(() => {
      setloadingsplash(false);
    }, 4000); // 3000ms (3 seconds) delay

    // Clear the timeout if the component is unmounted
    return () => clearTimeout(splashTimeout);
  }, []);


    const [loading, setLoading] = useState(false);
    const shareUrl = 'https://bababooki.in/';

    const shareCaption = 'Check out this awesome website: bababooki.in';
    const instagramUrl = `https://www.instagram.com/create/captioned/?caption=${encodeURIComponent(shareCaption)}`;

    sessionStorage.setItem("token", "");
    const [user, setUserts] = useState({
        mobile: "",
    });

    const [motp, setMotp] = useState(
        {
            mobile: "9782140792",
            otp: "123456"
        },
    )

    const [otpsand, setOtpsend] = useState(null);
    const [isShown, setIsShown] = useState(false);
    const [mobile, mobilenumber] = useState();
    const [otpnumbers, otpnumber] = useState();

    function onInputChange(e) {
        mobilenumber(e.target.value);
    };
    function onInputChangeotp(e) {
        otpnumber(e.target.value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            method: "POST",
            phone_number: mobile
        }).then((response) => {

            if (response.status) {
                setIsShown(true)
            } else {
                setIsShown(false);
            }

        });
    }


    const navigate = useNavigate();

    const loginverfiy = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/otpverify`, {
                method: "POST",
                phone_number: mobile,
                otp: otpnumbers,
            });

            if (response.status === 200) {
                const MySwal = withReactContent(Swal)
                MySwal.fire({
                    title: <strong>Login Successfully</strong>,
                    icon: 'success',
                    timer: 1500
                }).then((result) => {
                    localStorage.setItem('token_store_cricket', response.data.data.token);
                    localStorage.setItem('username', response.data.data.user.username);
                    localStorage.setItem('userid', response.data.data.user.id);
                    localStorage.setItem('mobile', response.data.data.user.mobile);
                    window.location.href = '/';
                })
            } else {
                const MySwal = withReactContent(Swal)
                MySwal.fire({
                    title: <strong>Invalid OTP!</strong>,
                    icon: 'error',
                    timer: 1500
                }).then((result) => {

                })
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }


    const handleSharePost = () => {
        const win = window.open(instagramUrl, '_blank');
        win.focus();
    };
    function OffCanvasExample({ name, ...props }) {
      const [show, setShow] = useState(false);
      const [fullscreen, setFullscreen] = useState(true);
  
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      function handleShownew(breakpoint) {
        setShow(breakpoint);
  
        setFullscreen(true);
      }
      return (
        <>
  
          <Offcanvas show={show} onHide={handleClose} {...props}>
            <Offcanvas.Header>
              <Offcanvas.Title className="text-white border-bottom-custum"> Add To Home Screen</Offcanvas.Title>
              <div className="col-md-2 cancelbtn" onClick={() => handleShownew(false)}> Cancel </div>
  
            </Offcanvas.Header>
            <Offcanvas.Body className="text-white">
              <p className="text-center text-white content-pwa">
                This website has app functionality. Add it to your home screen to use it in fullscreen and while
                offline.
              </p>
              <div>
                <ul className="list-style-none">
                  <li className="d-flex "><i class="bi bi-box-arrow-up arrowcolor"></i><span>1 Press the 'Share' button</span>
                  </li>
                  <li><i class="bi bi-plus-square"></i><span>2 Press 'Add to Home Screen'</span></li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <button  onClick={handleShow}  class="btn-ronded border-0 text-light pwabtn" >
            {name}
          </button>
       </>
      );
    }

    return (
        <>
            <div className="leftContainer">
                <div style={{ overflowY: 'hidden' }} >
                    <div className="splash-overlay" />
                    <div className="Login_formgame">
                        <div className="d-flex justify-content-between align-items-center logininstallapp">
                        {/* <div className="apkwidth"> */}
                        {/* <button style={{background:'#9f0b43'}} className="btn btn-ronded text-light" onClick={handleInstallClick}>Install Web Application</button> */}
                        {/* </div>================================-```````````````````````````````````````` */}
                        {/* {isIOS && (
                <>
                  <div className="d-flex jusitfy-content-center">
                    {['Install Web Application IOS'].map((placement, idx) => (
                      <OffCanvasExample key={idx} placement={placement} name={placement} />

                    ))}
                  </div>
                </>

              )} */}
              {isIOS && (
                <>
                  <div className="pwabtn" >
                    {['Install Web Application IOS'].map((placement, idx) => (
                      <OffCanvasExample key={idx} placement={placement} name={placement} />

                    ))}
                  </div>
                </>

              )}

              {!isIOS && showInstallButton && (<div className="d-flex jusitfy-content-center">
                <button className="btn btn-ronded text-light pwabtn" style={{background:'#9f0b43'}} onClick={handleInstallClick}>Install Web Application</button>
              </div>)}
 {/* {showInstallButton &&    <button className="btn btn-ronded installapplication text-light" onClick={handleOnInstallClick}><i class="bi bi-download"></i> &nbsp; Install Web Application</button>} */}
              {/* {!isIOS && showInstallButton && (
                <button style={{background:'#9f0b43'}} className="btn btn-ronded text-light" onClick={handleInstallClick}>Install Web Application</button>
             )} */}
                        <div className="apklogin ">
                             
                             <a href="../assets/application.apk"><img src={apk} className="img-fluid" /></a>
                         </div>
                        </div>
                        <div className="form-container form_Login">

                            <h3 className=" text-danger">जो भाई क्रिकेट सट्टा लगाता है बिंदास होके खेलो हैंड टू हैण्ड पेमेंट लो।</h3>


                        </div>
                        <div className="form-container form_Login mt-2">

                            <h4 className=" text-white">इसके अंदर आप 100 रुपये का भी सेशन लगा सकते हो</h4>


                        </div>
                        <div className="d-flex justify-content-between align-items-center links">
                            <div className="text-light">
                                Share Now
                            </div>
                            <div className="sharebtn">
                                <FacebookShareButton
                                    url={shareUrl} >

                                    <i class="fa-brands fa-facebook"></i>
                                </FacebookShareButton>
                            </div>
                            <div className="sharebtn">
                                <WhatsappShareButton
                                    url={shareUrl}  >
                                    {/* title={qutes}   hashtags={hast} */}
                                    <i class="fa-brands fa-whatsapp"></i>
                                </WhatsappShareButton>
                            </div>
                            <div className="sharebtn">
                                <LinkedinShareButton
                                    url={shareUrl}   >
                                    <i class="fa-brands fa-linkedin-in "></i>
                                </LinkedinShareButton>
                            </div>



                            <button onClick={handleSharePost} className="sharebtn border-0 text-light"> <i class="fa-brands fa-instagram"></i> </button>



                        </div>
                        <div className="form-container form_Login">
                            <div className="logologin">
                                <img src={logo} className="img-fluid"></img>
                            </div>
                            <form method="post" id="user_login" className="form_login_2" onSubmit={onSubmit}>
                                <input type="hidden" name="_token" defaultValue="C7QRdqspHb1mwSzxeM2NJd9raXKwU7i4EfmBBHZ6" />
                                <input type="hidden" name="reffercode" id="reffercode" defaultValue />
                                <div className="w-100  mx-auto" >
                                    <div className="d-flex font-15 ">Log In  </div>
                                    <div className=" cxy flex-column" id="incheight">
                                        <div id="sign_up_success" />
                                        <div className="input-group" style={{ transition: 'top 0.5s ease 0s' }}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text border-radius" style={{ width: '80px', height: "48px" }}>+91</div>
                                            </div>
                                            <input className="form-control" required pattern="[0-9]{10}" name="mobile" id="mobile" type="tel" placeholder="Mobile number" value={mobile} style={{ transition: 'all 3s ease-out 0s' }} onChange={onInputChange} />
                                            <div className="invalid-feedback">Enter a valid mobile number</div>
                                        </div>
                                        {loading && (
                                            <div className="loader-container">
                                                <div className="loader"></div>
                                            </div>
                                        )}

                                        {/* <span classname="btn btn-info" id="sendotp" style={{marginLeft: '74%'}}>Send Otp</span> */}

                                        {isShown ? (
                                            <div className="input-group pt-2 otp" style={{ transition: 'left 0.5s ease 0s' }}>
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text border-radius" style={{ width: '80px', height: "48px" }}>OTP</div>
                                                </div>
                                                <input className="form-control" name="otp" id="otp" type="tel" onChange={onInputChangeotp} placeholder="Enter OTP" autoComplete="off" />
                                                <div className="invalid-feedback">Enter a correct OTP</div>
                                            </div>

                                        ) : null}

                                    </div>
                 

                                    {isShown  ? <button type="button" onClick={loginverfiy} className="bg-green refer-button w-100 text-white btn-primary cxy btn  otp_login submit_data" id="submit_data" name="submit_data" >Login</button> : <button type="submit" className="w-100 bg-green refer-button cxy  send-otp btn btn-primary text-white" id="send_ottp" >Send OTP</button>}

                                </div>
                            </form>
                            {/* <div id="myOffcanvas" class="offcanvas" tabindex="-1" aria-labelledby="offcanvasLabel">
      <div class="offcanvas-header">
        <h5 class="text-white border-bottom-custum" id="offcanvasLabel">Add To Home Screen</h5>
        <div class="col-md-2 cancelbtn" onclick="handleShownew(false)">Cancel</div>
      </div>
      <div class="offcanvas-body text-white">
        <p class="text-center text-white content-pwa">
          This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.
        </p>
        <div>
          <ul class="list-style-none">
            <li class="d-flex"><i class="bi bi-box-arrow-up arrowcolor"></i><span>1 Press the 'Share' button</span></li>
            <li><i class="bi bi-plus-square"></i><span>2 Press 'Add to Home Screen'</span></li>
          </ul>
        </div>
  </div>
</div>

<button type="button" class="btn btn-ronded text-light pwabtn" style={{BorderRadius:"15px"}} onclick="handleShow()">
  {name}
</button> */}
                        </div>
                    </div>

                </div>
  
            </div>

            <Outlet />
        </>
    );
}

export default Login;
