import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Football from './pages/Football/Football';
import Serieslist from './pages/Serieslist/Serieslist';
import Tennis from './pages/Tennis/Tennis';
import Cricket from './pages/Cricket/Cricket';
import Accountstatement from './pages/Accountstatement/Accountstatement';
import Bethistory from './pages/Bethistory/Bethistory';
import Profitlossreport from './pages/Profitlossreport/Profitlossreport';
import Secureauth from './pages/Secureauth/Secureauth';
import Unsettledbet from './pages/Unsettledbet/Unsettledbet';
import Setbuttonnew from "./pages/Setbuttonnew/Setbuttonnew"
import Depositchat from './pages/ChatPage/Depositchat';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Row, Col,} from 'react-bootstrap';
import Newsecurity from "./pages/Security/Newsecurity"
import Newpassword from "./pages/password/Newpassword"
import Login from './pages/Login/Login';
import Setbuttonvalue from "./pages/Setbuttonvalue/Setbuttonvalue"
import ReactDOM from 'react-dom'; // Import ReactDOM
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import Logo from './img/logo.png';
import Swal from 'sweetalert2'; // Import SweetAlerts
import BroadCast from './pages/BroadCast/BroadCast'
function App() {
  

  // useEffect(() => { 
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };
  
  //   const handleKeyDown = (e) => {
  //     if (e.key === "F12" || e.key === "F12" || e.keyCode === 123) {
  //       e.preventDefault();
  //     }
  //   };
  
  //   document.addEventListener("contextmenu", handleContextMenu);
  //   document.addEventListener("keydown", handleKeyDown);
  
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  
  const segment = window.location.pathname.split("/");

  if (segment[1] === 'login') {
  
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
  
      <Routes>
        <Route
          path="/*"
          element={
            <div>
              <Header />
              <Sidebar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/football" element={<Football />} />
                <Route path="/tennis" element={<Tennis />} />
                <Route path="/cricket" element={<Cricket />} />
                <Route path="/BroadCast" element={<BroadCast />} />
                <Route path="/Depositchat" element={<Depositchat />} />
                <Route path="/account-statement" element={<Accountstatement />} />
                <Route path="/bet-history" element={<Bethistory />} />
                <Route path="/series-list" element={<Serieslist />} />
                <Route path="/profit-loss-report" element={<Profitlossreport />} />
                <Route path="/secure-auth" element={<Secureauth />} />
                <Route path="/unsettled-bet" element={<Unsettledbet />} />
                <Route path="/set-button" element={<Setbuttonnew />} />
                <Route path="/New-security" element={<Newsecurity />} />
                <Route path="/Newpassword" element={<Newpassword />} />
                <Route path="/Setbuttonvalue" element={<Setbuttonvalue />} />
              </Routes>
              <Routes>
              <Route path="/Depositchat" element={null} /> 
              <Route path="*" element={<Footer />} />
      </Routes>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
// serviceWorker.register();
export default App;
