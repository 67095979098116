import React, { useEffect, useState } from 'react';
import logo from '../../img/criket-logo.png';
import { Link } from 'react-router-dom';
import { GiHouse } from "react-icons/gi";
import { GiWallet } from "react-icons/gi";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import { PiChatsCircleDuotone } from "react-icons/pi";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import $ from 'jquery';
import { fetchwalletamount } from '../../common.js';
import Swal from 'sweetalert2';
import callimg from '../../img/callimg.png';
import chatbtn from '../../img/chatbtn.png';

import axios from "axios";

function Header() {

  const [notification, setNotification] = useState('');
  const notificationcount = localStorage.getItem("notificationCount");
  const notificationShownFlag = localStorage.getItem('notificationShownFlag');
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("token_store_cricket");
  // const [Notification, setNotification] = useState('');
  const [notices, setNotices] = useState([]);
  const [walletAmount, setWalletAmount] = useState(null);
  // const [winAmount, setWinAmount] = useState(null);

  const [expamount, setexpamount] = useState(null);
  const username = localStorage.getItem('username');
  const userid = localStorage.getItem('userid');
  const mobile = localStorage.getItem('mobile');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [depositshow, setDepositshow] = useState(false);

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [inputValuedeposit, setInputValuedeposit] = useState('');

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const depositClose = () => setDepositshow(false);
  const depositShow = () => setDepositshow(true);

  const [bankName, setBankName] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');

  const [inputError, setInputError] = useState(false);
  const [bankNameError, setBankNameError] = useState(false);
  const [accountHolderNameError, setAccountHolderNameError] = useState(false);
  const [accountNumberError, setAccountNumberError] = useState(false);
  const [ifscCodeError, setIfscCodeError] = useState(false);

  const [error, setError] = useState('');
  const [reloadPage, setReloadPage] = useState(false);
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
    console.log('page to reload')
  }
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token_store_cricket");
    window.location.href = '/login';
  }

  if (!token) {
    window.location.href = '/login';
  }

  useEffect(() => {
    const toggleSidebarBtn = $('.toggle-sidebar-btn');
    if (toggleSidebarBtn.length) {
      toggleSidebarBtn.on('click', function () {
        $('body').toggleClass('toggle-sidebar');
      });
    }
  }, []);


  useEffect(() => {
    const spinnerWrapper = document.querySelector('.spinner-wrapper');

    if (spinnerWrapper) {
      setTimeout(() => {
        spinnerWrapper.style.display = 'none';

      }, 1000);
    }
  }, []);

  const noticesshow = async () => {
    const token = localStorage.getItem('token_store_cricket');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/manage-notice`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data.data.data.description);
      setNotices(data.data.data.description);

    } catch (error) {
      console.error('Error fetching sport data:', error);
    }
  };

  useEffect(() => {
    noticesshow();
  }, []);


  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
    setInputValue(amount);
  };


  const handleButtonClickdeposit = (amount) => {
    setSelectedAmount(amount);
    setInputValuedeposit(amount);
  };


  const showError = (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: message,
      timer: 2000,
    });
  };

  const handleWithdrawal = async () => {
    setIsButtonDisabled(true);

    try {
      const errors = [];
      setInputError(false);
      setBankNameError(false);
      setAccountHolderNameError(false);
      setAccountNumberError(false);
      setIfscCodeError(false);

      const amountPattern = /^\d+(\.\d{1,2})?$/;
      const bankNamePattern = /^[A-Za-z\s]+$/;
      const accountHolderNamePattern = /^[A-Za-z\s]+$/;
      const accountNumberPattern = /^\d+$/;
      // const ifscCodePattern = /^[A-Za-z]{4}\d{7}$/;
      const ifscCodePattern = /^[A-Za-z\s\d]+$/;


      if (!bankName.match(bankNamePattern)) {
        setBankNameError(true);
        errors.push('Please enter a valid bank name (letters and spaces only)');

      }


      if (!accountHolderName.match(accountHolderNamePattern)) {
        setAccountHolderNameError(true);
        errors.push('Please enter a valid account holder name (letters and spaces only)');
      }


      if (!accountNumber.match(accountNumberPattern)) {
        setAccountNumberError(true);
        errors.push('Please enter a valid account number (numbers only)');
      }


      if (!ifscCode.match(ifscCodePattern)) {
        setIfscCodeError(true);
        errors.push('Please enter a valid IFSC code (e.g., ABCD1234567)');
      }


      if (!inputValue) {
        setInputError(true);
        errors.push('Please enter a valid amount (e.g., 500 or 500.00)');
      }


      if (errors.length > 0) {
        errors.push(errors.join('\n'));
        return;
      }

      // if (errors.length > 0) {
      //   showError(errors.join('\n'));
      //   return;
      // }

      const formData = new FormData();
      formData.append("amount", inputValue);
      formData.append("bank_name", bankName);
      formData.append("account_holder_name", accountHolderName);
      formData.append("account_number", accountNumber);
      formData.append("ifsc_code", ifscCode);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/withdrawal`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your Amount Withdraw Successfully',
          timer: 2000,
          timerProgressBar: true,
        });
        setInputValue('');
        setBankName('');
        setAccountHolderName('');
        setAccountNumber('');
        setIfscCode('');
        setReloadPage(true);
        fetchwalletamount(setWalletAmount);

        handleClose();
      }

      else if (response.status === 422) {
        const data = await response.json();
        Swal.fire({
          icon: 'error',
          title: 'Minimum Withdraw 500',
          text: 'Your account balance is insufficient to complete this transaction.',
          timer: 3000,
        });
      }
      else {
        console.error("Withdrawal failed");
        Swal.fire({
          icon: 'error',
          title: 'Withdrawal Failed',
          text: 'An error occurred during withdrawal',
          timer: 3000,
        });
      }
    }

    catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred',
        timer: 2000,
      });
    }
  };


  const [mobileNumber, setMobileNumber] = useState("");

  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token_store_cricket");
      const apiUrl = `${process.env.REACT_APP_API_URL}/general-setting`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const data = await response.json();
      setMobileNumber(data.data.mobile);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);



  const handleDeposit = () => {
    if (inputValuedeposit) {
      const apiUrl = `https://bababooki.in/api/deposit.php?name=${username}&userid=${userid}&amount=${inputValuedeposit}&contact=${mobile}`;
      window.location.href = apiUrl;
    } else {
      setError('Please enter an amount before depositing.');
    }
  };

  useEffect(() => {
    fetchwalletamount(setWalletAmount, setexpamount);

  }, []);

  // const [loading1, setLoading] = useState(false);
  // alert("fmbjgfdgj")
  useEffect(() => {
    playGame();
    setTimeout(() => {

      if (!notificationShownFlag && notification !== 0) {

        localStorage.setItem('notificationShownFlag', true);
      }
    }, 1000);
    // setTimeout(() => {
    // }, 1000);


  }, []);
  const playGame = async () => {
    try {
      const formData = new FormData();
      formData.append('user_id', userid);

      const response = await axios.post('https://bababooki.in/api/broadcast.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      const result = response.data.data;
      setUsers(result);
      const noteFiCation = Math.max(result.length - notificationcount, 0);
      setNotification(noteFiCation);
      console.warn(noteFiCation);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const playGame = async () => {
  //   // alert("jdfgjdf")
  //   try {
  //     const formData = new FormData();
  //     formData.append('user_id', userid);

  //     const response = await axios.get(
  //       `https://bababooki.in/api/broadcast.php`
  //     );
  //     const result = response.data.data;
  //     // console.warn(result);
  //     // setUservideosss(result);
  //     setUsers(result);
  //     const noteFiCation = Math.max(result.length - notificationcount, 0);

  //     setNotification(noteFiCation);
  //     // setNotification(noteFiCation);
  //     console.warn(noteFiCation);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const handleReload = () => {
    window.scrollTo(0, 0);
    playGame();
  };
  return (

    <>
      <div className="spinner-wrapper">
        <div className="spinner">
          <div>
            <div className="mesh-loader">
              <div className="set-one">
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
              <div className="set-two">
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>

        <header id="header" className="header fixed-top d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-between">
            <Link to="/" className="homebtn">
              <GiHouse />
            </Link>
            <div className="d-flex justify-content-start align-items-center logoposition ">

              <Link to="/" className="logo d-flex align-items-center text-decoration-none">
                <img src={logo} alt="" />
              </Link>
            </div>
            <i className="bi bi-list toggle-sidebar-btn text-white"></i>
          </div>


          <div className="offermarquee d-flex align-items-center">
            {/* <div className="news">News</div> */}
            <marquee className="text-white">{notices}</marquee>
          </div>

          <div className="btn-for-withdrawal">
            <div className="withdrawal">
              <Button className="withdrawal btn bg-color-section border-0" variant="primary" onClick={handleShow}>
                Withdrawal
              </Button>
            </div>

            <div className="deposit">
              <Button className="deposit btn bg-color-section border-0" variant="primary" onClick={depositShow}>
                Deposit
              </Button>
            </div>
          </div>


          <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-start justify-content-between  ulnav">
              {/* <ul className="d-flex align-items-center justify-content-between flex-wrap-mobile ulnav"> */}


              <li className="nav-item text-white balance_amount mx-2" style={{ textAlign: "center" }}>

                <Link to="/BroadCast" className='text-decoration-none ' style={{ display: "flex", alignItems: "start" }}>
                  <i className="bi bi-bell" style={{ fontSize: "26px" }} onClick={handleReload}></i>
                  {notification !== 0 && (<Badge bg="rounded-pill" className='badgecontent'>
                    <span>{notification}</span>

                  </Badge>)}
                  <span className="visually-hidden">unread messages</span>
                </Link>
              </li>

              <li className="nav-item text-white balance_amount text-left">
                <p ><strong>   <GiWallet className='wallet' /></strong>{walletAmount}</p>
                <div className="d-flex">
                  <span> Exp:</span> <p className='expenseAmtt'>{expamount}</p>
                </div>
              </li>

              <li className="nav-item dropdown text-white">
                <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                  <span className="d-md-block dropdown-toggle  usename_details text-white mx-2">{mobile}</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item d-flex align-items-center nav-link" to="/account-statement">
                      <i className="bi bi-person"></i>
                      <span>Account Statement</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {/* <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/profit-loss-report">
                      <i className="bi bi-gear"></i>
                      <span>Profit Loss Report</span>
                    </Link>
                  </li> */}

                  <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/bet-history">
                      <i class="bi bi-file-earmark-break-fill"></i>
                      <span>Bet History</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/unsettled-bet">
                      <i className="bi bi-question-circle"></i>
                      <span>Unsetteled Bet</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item d-flex align-items-center" to="/Setbuttonvalue">
                      <i className="bi bi-question-circle"></i>
                      <span>Set button value</span>
                    </Link>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="/"
                      onClick={logout}
                    >
                      <i className="bi bi-box-arrow-right"></i>
                      <span>Sign Out</span>
                    </Link>
                  </li>
                </ul>
                <div>
                <div className="callimg">
              <a
                href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(mobileNumber)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center"
              >
                <img className="numbercall" src={callimg} />
              </a>
            </div>
                </div>
                <div>   
                  <Link to="/Depositchat">
                  <button className='btn-chat' style={{padding:"2px 7px", whiteSpace:"nowrap"}}>
                    <PiChatsCircleDuotone className='fs-4'/>Chat</button>
                </Link></div>
                <div>
                  <button className='btn-refesh' onClick={refreshPage}>Refresh<GiAnticlockwiseRotation /></button>
                </div>              </li>
              {/* <li className="nav-item text-white balance_amount d-flex align-items-center" style={{ textAlign: "center" }}>
        
              </li> */}
              <li>

              </li>
            </ul>
          </nav>
        </header>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background: '#9f0b43' }}>
          <Modal.Title className='text-light'>Withdrawal <small>Min ₹500 /-</small></Modal.Title>
          {/* <Button className="btn btn-amount border-0 bg-color-section" ><strong>   <GiWallet  className='wallet' />₹ </strong>{walletAmount}</Button> */}

        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12 mx-auto">

                <div className="bg-white  mb-4">

                  <form id="form-send-money" method="post">
                    <div className="mb-3">
                      <label for="youSend" className="form-label mb-0">Amount</label>
                      <div className="input-group">
                        <span className="input-group-text">₹</span>
                        <input
                          type="number" className={`form-control ${inputError ? 'is-invalid' : ''}`}
                          id="youSend" value={inputValue} onChange={(e) => setInputValue(e.target.value)}
                          placeholder="Amount"
                        />
                        {inputError && (
                          <div className="invalid-feedback">Please enter a valid amount (e.g., 500 or 500.00)</div>
                        )}
                      </div>
                      <div className="d-flex flex-wrap">
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClick(500)}>500</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClick(600)}>600</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClick(700)}>700</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClick(800)}>800</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClick(900)}>900</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClick(1000)}>1000</Button>
                      </div>
                    </div>
                    <div className="mb-3">

                      <Form.Group md="4" className="mb-2" controlId="validationCustom01">
                        <Form.Label className='mb-0'>Bank Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Bank Name"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                          className={`form-control ${bankNameError ? 'is-invalid' : ''}`}
                        />
                        {bankNameError && (
                          <div className="invalid-feedback">Please enter a valid bank name (letters and spaces only)</div>
                        )}
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group md="4" className="mb-2" controlId="validationCustom01">
                        <Form.Label className='mb-0'>Account Holder Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Account Holder Name"
                          value={accountHolderName}
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          className={`form-control ${accountHolderNameError ? 'is-invalid' : ''}`}
                        />
                        {accountHolderNameError && (
                          <div className="invalid-feedback">Please enter a valid account holder name (letters and spaces only)</div>
                        )}
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group md="4" className="mb-2" controlId="validationCustom01">
                        <Form.Label className='mb-0'>Account Number</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Account Number"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          className={`form-control ${accountNumberError ? 'is-invalid' : ''}`}
                        />
                        {accountNumberError && (
                          <div className="invalid-feedback">Please enter a valid account number (numbers only)</div>
                        )}
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group md="4" className="mb-2" controlId="validationCustom01">
                        <Form.Label className='mb-0'>IFSC Code</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="IFSC Code"
                          value={ifscCode}
                          onChange={(e) => setIfscCode(e.target.value)}
                          className={`form-control ${ifscCodeError ? 'is-invalid' : ''}`}
                        />
                        {ifscCodeError && (
                          <div className="invalid-feedback">Please enter a valid IFSC code (e.g., ABCD1234567)</div>
                        )}
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    {handleWithdrawal ? (

                      <div className="d-grid"><button type='button' className="btn text-white submitbtn" onClick={handleWithdrawal} disabled={isButtonDisabled}>Submit</button></div>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>
      {/* depoist */}
      <Modal show={depositshow} onHide={depositClose}>
        <Modal.Header closeButton className='text-light' style={{ background: '#9f0b43' }}>
          <Modal.Title>Deposit <small>Min ₹100/-</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12 mx-auto">

                <form id="form-send-money" method="post">
                  <div className="bg-white  rounded mb-4">
                    <div className="mb-3">
                      <label for="youSend" className="form-label mb-0">Amount</label>
                      <div className="input-group">
                        <span className="input-group-text">₹</span>
                        <input type="number" className="form-control" data-bv-field="youSend" id="youSend" value={inputValuedeposit} placeholder="Enter Amount" onChange={(e) => setInputValuedeposit(e.target.value)} required />
                      </div>
                      {error && <div className="text-danger">{error}</div>}
                      <div className="d-flex flex-wrap">
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClickdeposit(200)}>200</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClickdeposit(400)}>400</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClickdeposit(600)}>600</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClickdeposit(700)}>700</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClickdeposit(800)}>800</Button>
                        <Button className="btn btn-amount border-0 bg-color-section" onClick={() => handleButtonClickdeposit(1000)}>1000</Button>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center bg-green"><button type='button' className="btn submitbtn text-white" onClick={handleDeposit}>Deposit</button></div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>



    </>
  )
}

export default Header;