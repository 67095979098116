
import React, { useState, useEffect, useRef } from 'react';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import logo from "../../img/imglogo.png";
import { Link } from 'react-router-dom'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import $ from 'jquery';
import { Spinner } from "react-bootstrap";
import pdf from "../../img/png-transparent-pdf-icon-thumbnail.png";
import '../ChatPage/chatcss.css';

export default function Gameposting() {
  const [isRecording, setIsRecording] = useState(false);
  const [users, setUsers] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [Messageadio, setMessageadio] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isInputFocuseddd, setIsInputFocuseddd] = useState(false);
  const [recordedData, setRecordedData] = useState(null);
  const [recordurl, setRecordeurl] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonDisabled1, setIsButtonDisabled1] = useState(false);
  const [loadingbutton, setLoadingbutton] = useState(true);
  const [loadingbuttonaudio, setLoadingbuttonaudio] = useState(false);
  const isButtonLoading = useRef(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const intervalRef = useRef(null);
  const user_id = localStorage.getItem("userid");
  const token = localStorage.getItem("token_store_cricket");
  const [imgurl, setImgUrl] = useState("");
  const fileInputRef = useRef(null);

  const handleClick = () => {
    const element = document.querySelector('#cloesbtn');
    element.classList.add('display_none');
  };

  const recorderControls = useAudioRecorder()
  // const addAudioElement = (blob) => {
  //   setIsRecording(false);
  //   const url = (blob);
  //   console.warn("url",url)
  //   setRecordeurl(url)

  //   // alert(url)
  //   const audio = document.createElement("audio");
  //   audio.src = url;
  //   audio.controls = true;
  //   document.body.appendChild(audio);
  //   console.warn("audio",document.body.appendChild(audio))
  //   sendMessageRecording(url);
  //   setMessageadio(audio);
  // };
  const addAudioElement = (file) => {
    setIsRecording(false);


    const url = URL.createObjectURL(file);
    console.warn("url", url);
    setRecordeurl(url);

    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;


    // document.body.appendChild(audio);
    sendMessageRecording(file);

    setMessageadio(audio); // Store the file object

  };

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  useEffect(() => {
    $('.sendmessage').hide();
    loaduser();
    // window.scrollToBottom(0, 0);
    const interval = setInterval(() => {
      loaduser();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // ------------------------------Chat-List API---------------------------------------
  const loaduser = async () => {

    try {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_API_URL}/chatlist`;

      const response = await fetch(apiUrl, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      // console.warn(result.data);
      setUsers(result.data);
      setImgUrl(result.url)
      // alert(result.url);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const onRecordStart = () => {
    setIsRecording(true);
  };

  const onRecordStop = (recordedBlob) => {
    setIsRecording(false);
    setRecordedData(recordedBlob.blob);
    console.log(recordedBlob);
  };
  const OnnumberChange = (e) => {
    // $('.sendmessageMic').hide();
    // $('.sendmessage').show();
    const mobilnumbers = e.target.value;
    setMessage(mobilnumbers);
    setIsInputFocused(mobilnumbers);

    if (mobilnumbers.trim()) {
      $('.sendmessageMic').hide(); 
      $('.sendmessage').show(); 
    } else {
      $('.sendmessageMic').show(); 
      $('.sendmessage').hide(); 
    }

  };

  const onFileChange = (e) => {
    // alert('op');
    // Handle file selection (image, audio, video)

    $('.sendmessageMic').hide();
    $('.sendmessage').show();
    const file = e.target.files[0];
    console.warn("io",file);
    setMessage("");
    setSelectedFile(file);
    setIsInputFocused(file);
  };

  const clearInput = () => {
    setMessage("");
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset file input
    }
    $('.sendmessageMic').show(); // Show mic if no text
    $('.sendmessage').hide(); // Hide send button
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset file input
    }
    $('.sendmessageMic').show(); // Show mic if no text
    $('.sendmessage').hide(); // Hide send button
  };


  // -------------------------------------Chat-Store-Audio-Api-------------------------------------------

  const sendMessageRecording = (file) => {
    // setIsButtonDisabled1(true);
    // alert('ppppp');
    if (isRecording) {
      console.log('Recorded Data:', recordedData);
    } else {
    }
    // alert(recordurl1);
    if (!file) {
      toast.error("Please enter a message or select a file before sending.");
      return;
    }

    try {
      // setLoadingbuttonaudio(true);
      const formData = new FormData();
      // const audioFile = new File([recordurl], 'recording.wav', { type: 'audio/wav' });
      // formData.append("file", recordurl);
      console.warn(file);
      formData.append("type", 'audio');
      if (file) {
        // console.warn(file)
        formData.append("file", file);
      }

      let url = `https://bababooki.in/cadmin/api/v1/chatstore-audio`;

      // Configure the request
      const config = {
        method: "POST",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      axios.post(url, formData, config).then(function (response) {
        // const res = JSON.stringify(response.data);
        console.warn(response);
        if (response.data.success == 1) {
          setMessage("");
          setSelectedFile("");
          setRecordeurl("");

          toast.success(response.data.message, {
            onClose: () => {
              setTimeout(() => {
                loaduser();
              }, 1000);
            },
          });
          // window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      });
    } catch (error) {
      console.error("User Name Update:", error);
    }
    finally {
      setLoadingbuttonaudio(false);
    }
  };

  const handleClicksend = () => {
    if (!isButtonClicked) {
      setIsButtonClicked(true);
      sendMessage();
      setMessage("");
      setSelectedFile(null);
      setRecordeurl("");

      if (fileInputRef.current) {
        fileInputRef.current.value = null; // Reset file input
      }
  
      // Clear previous interval
      clearInterval(intervalRef.current);

      // Set a new interval to reset isButtonClicked after 3 seconds
      intervalRef.current = setInterval(() => {
        clearInterval(intervalRef.current);
        setIsButtonClicked(false);
      }, 1000);
    }
  };


  // -------------------------------------Chat-Store-Api-------------------------------------------
  const sendMessage = () => {
    setIsButtonDisabled(true);
    if (isRecording) {
      console.log('Recorded Data:', recordedData);
    } else {
    }

    const user_id = localStorage.getItem("userid");
    // alert(recordurl);
    if (message.trim() === "" && !selectedFile && !recordurl) {
      toast.error("Please enter a message or select a file before sending.");
      return;
    }
    if (!isButtonLoading.current) {
      isButtonLoading.current = true;

      setIsButtonDisabled(true);
      // setTimeout(() => {
      setIsButtonDisabled(false);
      isButtonLoading.current = false;
      // }, 1000);
    }
    try {
      setLoadingbutton(true);
      const formData = new FormData();
      // formData.append("message", message);
      if (selectedFile) {
        formData.append("message", selectedFile);
        formData.append("type", 'image');
      }
      if (recordurl) {
        // console.warn(recordurl)
        formData.append("file", recordurl);
        formData.append("type", 'audio');
      }
      if (message) {
        // console.warn(recordurl)
        formData.append("message", message);
        formData.append("type", 'text');
      }

      let url = `https://bababooki.in/cadmin/api/v1/chatstore`;

      // Configure the request
      const config = {
        method: "POST",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      axios.post(url, formData, config).then(function (response) {
        const res = JSON.stringify(response.data);
        if (response.data.success == 1) {
          console.warn(response.data.message);

          setMessage("");
          setSelectedFile("");
          setRecordeurl("");
          setIsInputFocused(true);
          $('.sendmessageMic').show();
          $('.sendmessage').hide();
          toast.success(response.data.message, {
            onClose: () => {
              setTimeout(() => {
                loaduser();
              }, 1000);
            },
          });
          // window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      });
    } catch (error) {
      console.error("User Name Update:", error);
    }
    finally {
      setLoadingbutton(false);
    }
  };


  return (
    <>
      {isRecording ? (<><div className='bg-mic' id='cloesbtn'>
        <button className='btn btn-close-custum' onClick={handleClick}>X</button>

        <div
          className='voicechange'>
          {/* <img src={mic} className='mic' /> <VoiceRecorder onClick={handleStopRecording} /></div></div></>) : (<></>)} */}
          {/* <img src={mic} className='mic' />   */}
          {/* <AudioRecorder
            onRecordingComplete={(blob) => addAudioElement(blob)}
            recorderControls={recorderControls}
          /> */}
          {/* <button className='btn' onClick={recorderControls.stopRecording}><span className='block-red'></span></button> */}

        </div></div>
        </>
      ) : (
        <></>
      )}
      <div className="card-header tab_first d-flex">
      <main id="main" class="main new_chat">
        <section className="message-area">
                <div className="chat-area">
                  {/* chatbox */}
                  <div className="chatbox">
                    <div className="modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="msg-body">
                            <ul>
                              {users && users.map((value) => (
                                <div className={`message-container ${value.sender === 'reply' ? 'messageadmin' : ''}`} key={value.id}>
                                  <div>
                                    <div className={`${value.sender === 'user' ? '' : ''}`}>
                                      {/* Handling different chat types */}
                                      {value.type === 'text' ? (
                                        <li className={value.sender === 'user' ? 'reply' : 'sender'}>
                                          <p className='text-black' style={{ wordBreak: 'break-word' }}>
                                            {value.message ? value.message : value.message}
                                          <span className="time">
                                            {new Date(value.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                          </span>
                                          </p>
                                        </li>
                                      ) : value.type === 'image' && value.sender === 'user' ? (
                                        <li className={value.sender === 'user' ? 'reply' : 'sender'}>
                                          {/* // <a href={`${"https://bababooki.in/cadmin/admin/uploads/chatimage"}/${value.message}`} rel="noopener noreferrer" className='text-dark'> */}
                                          <img src={`${imgurl}/${value.message}`} alt={value.message} />
                                          <span className="time">
                                            {new Date(value.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                          </span>
                                        </li>
                                        // </a>
                                      ) : value.type === 'video' ? (

                                        <video controls width="100%" height="150px">
                                          <source src={value.url} />
                                        </video>
                                      ) : value.type === 'audio' && value.sender === 'user' ?  (
                                        <li className={value.sender === 'user' ? 'reply' : 'sender'}>
                                          <audio controls className='audioclass'>
                                            {/* <source src={recordurl} /> */}
                                            <source src={`${imgurl}/${value.message}`} type="audio/mp3" />
                                          </audio>
                                        </li>
                                      ) : null}

                                    </div>

                                    {/* Displaying time */}
                                    {/* <p className="time">
                {new Date(value.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </p> */}
                                  </div>
                                </div>
                              ))}



                            </ul>
                          </div>
                        </div>
                        <div className="send-box">
                          <div className="inputchat">

                          </div>
                          <form action="" onSubmit={(e) => { e.preventDefault(); clearInput(); }}>

                            <textarea
                              className="form-control"
                              aria-label="message…"
                              placeholder="Write message…"
                              onChange={OnnumberChange}
                              value={message + (selectedFile ? ` - ${selectedFile.name}` : recordurl ? recordurl : "")}
                            />
                            {/* {selectedFile && (
                                <button
                                  type="button"
                                  onClick={removeSelectedFile}
                                  className="close-button"
                                  style={{
                                    position: 'absolute',
                                    bottom: 57,
                                    right: 61,
                                    background: 'none',
                                    border: 'none',
                                    color: 'red',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  &times; 
                                </button>
                              )} */}
                           
                            <div className="buttonsend bg-info">
                              <label
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="bi bi-paperclip"
                                  style={{ transform: "rotate(60deg)", fontSize: "30px" }}
                                ></i>
                                <label htmlFor="multipleFileInput"></label>
                                <input
                                  type="file"
                                  onChange={onFileChange}
                                  id="multipleFileInput"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                />
                              </label>
                            </div>


                            <div className="buttonsend">

                              <div className="sendmessage">
                                <i onClick={handleClicksend} className="bi bi-send message_send">{isButtonClicked && <Spinner />}</i>
                              </div>
                              <div className="sendmessageMic">
                                <AudioRecorder
                                  onRecordingComplete={(file) => addAudioElement(file)}
                                  // recorderControls={recorderControls}
                                />
                                <div className='sendmessagebtn'>
                                  <i onClick={recorderControls.stopRecording} disabled={loadingbuttonaudio || isButtonDisabled1}
                                    className="bi bi-send">{loadingbuttonaudio && <Spinner />}</i></div>
                              </div>
                              {/* <i onClick={sendMessage} className="bi bi-send"></i> */}
                            </div>

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

        </section>
      </main>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}
